export default function Card(props) {
    return (
        <div className="col-xs-6 col-md-4 col-lg-2 text-center">
            <a href={props.link}
               className="card portfolio-item portfolio-title text-center nav-link"
               target="_blank" rel="noreferrer nofollow">
                    <div className="card-header bg-gradient bg-dark portfolio-title">{props.title}</div>
                <div className="card-body">
                    <div className="card-img">
                        <img src={props.image}
                             alt={props.title}
                             width="180"
                             height="180"
                             className="img-thumbnail portfolio-item"/>
                    </div>
                </div>
            </a>
        </div>
    )
};