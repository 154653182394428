export default function Line(props) {
    const subtitle = props.subtitle ?? null;
    let card;
    if (subtitle && subtitle.length > 0) {
        card = (
            <div className="card">
                <div className="card-body line">
                    <div className="float-end text-dark-gray">{props.subtitle}</div>
                    <div className="line-text">{props.title}</div>
                </div>
            </div>
        )
    } else {
        card = (
            <div className="card">
                <div className="card-body line">
                    <div className="line-text">{props.title}</div>
                </div>
            </div>
        )
    }

    return (
        <div className="mb-3">{card}</div>
    )
};