import {Component} from "react";
import Translator from "../translator/Translator";

export default class Header extends Component {
    getHomeUrl() {
        switch (Translator.getCurrentLocale()) {
            case "uk":
                return "/uk/"
            case "ru":
                return "/ru/"
            default:
                return "/"
        }
    }

    render() {
        const currentLocale = Translator.getCurrentLocale()
        const ucfirst = (string) => string.charAt(0).toUpperCase() + string.slice(1)

        return (
            <div className="container transparent-dark">
                <div className="container navbar">
                    <div className="navbar-brand p-3">
                        <a href={this.getHomeUrl()} rel="nofollow" className="nav-link link-light">
                            {Translator.trans("Oleksandr Riabokin")}
                        </a>
                    </div>
                    <div className="navbar nav btn-group">
                        {
                            Translator.getAvailableLocales().map(locale => {
                                if (currentLocale === locale) {
                                    return (
                                        <div className="btn disabled text-bg-dark link-light" key={locale}>
                                            {ucfirst(locale)}
                                        </div>
                                    )
                                }
                                const path = locale === Translator.getDefaultLocale() ? "/" : `/${locale}/`
                                return (
                                    <a href={path} rel="alternate" hrefLang={locale} key={locale}
                                       className="btn btn-outline-light">
                                        {ucfirst(locale)}
                                    </a>
                                )
                            })
                        }
                    </div>
                    <div className="navbar-nav">
                        <a className="btn btn-outline-light"
                           href={`/pdf/${Translator.getCurrentLocale()}/Oleksandr_Riabokin_-_Fullstack_PHP_JS_Developer.pdf`}
                           rel="noreferrer"
                           type="application/pdf"
                           download={`Oleksandr_Riabokin_-_Fullstack_PHP_JS_Developer_-_${currentLocale}.pdf`}
                           target="_blank">
                            {Translator.trans("Download as PDF")}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}