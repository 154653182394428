import Line from "../page/Line";
import Card from "../page/portfolio/Card";
import Translator from "../translator/Translator";

export default function Portfolio() {
    return (
        <div className="app-cv-portfolio mb-5">
            <Line title={Translator.trans("Portfolio")}></Line>
            <div className="container mb-5">
                <div className="row d-flex justify-content-around">
                    <Card title="ZooZy" link="https://zoozy.co/" image="/images/zoozy.webp"></Card>
                    <Card title="Gloice" link="https://gloice.net/" image="/images/gloice.webp"></Card>
                    <Card title="Bonus Car" link="http://rap.ltd.ua/" image="/images/bonus_car.webp"></Card>
                </div>
            </div>
        </div>
    )
};