import './App.scss';
import Header from "./components/page/Header";
import Personal from "./components/cv/Personal";
import Portfolio from "./components/cv/Portfolio";
import Experience from "./components/cv/Experience";
import {Helmet} from "react-helmet";
import Translator from "./components/translator/Translator";

function gtag() {
    window.dataLayer.push(arguments);
}

function App() {
    window.dataLayer = window.dataLayer || [];

    gtag('js', new Date());
    gtag('config', 'G-1MT5D9ER50');

    const meta = {
        title: Translator.trans("Oleksandr Riabokin"),
        description: Translator.trans("CV and Portfolio by Oleksandr Riabokin"),
        canonical: 'https://alex1rap.me/',
        keywords: Translator.trans("alex1rap, oleksandr riabokin, cv, portfolio, fullstack, php, js, developer")
    }

    return (
        <div className="App">
            <Helmet defaultTitle={meta.title}>
                <title>{meta.title}</title>
                <meta name="description" content={meta.description}/>
                <meta itemProp="description" content={meta.description}/>
                <meta name="keywords" content={meta.keywords}/>
                <meta itemProp="keywords" content={meta.keywords}/>
            </Helmet>
            <div className="App-content">
                <Header></Header>
                <div className="container transparent-dark">
                    <div>
                        <Personal></Personal>
                        <Experience></Experience>
                        <Portfolio></Portfolio>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
